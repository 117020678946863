<template>
  <div style="margin-top: 20px;">
    <el-form label-width="100px">
      <div style="display:flex;justify-content:space-between;align-items:center;">
        <div class="form-title">图表绑定</div>
        <div>
          <el-button type="danger" size="small" @click="closeChart" :disabled="chartList.length==0">清空图表</el-button>
          <el-button type="primary" size="small" @click="selectChart">选择图表</el-button>
        </div>
      </div>
      <el-table :data="chartList" style="width: 100%">
        <el-table-column prop="graphName" label="图表名称"></el-table-column>
        <el-table-column prop="statisticsDimension" label="图表类型">
          <template slot-scope="scope">
            <!-- {{ scope.row.statisticsDimension=='realtime'?'实时统计'
            :scope.row.statisticsDimension=='total'?'累计统计'
            :scope.row.statisticsDimension=='trend'?'趋势统计'
            :scope.row.statisticsDimension=='contrast'?'对比统计'
            :scope.row.statisticsDimension=='merge-trend'?'合并趋势统计'
            :'-' }} -->
            <span v-if="scope.row.statisticsDimension=='realtime'" size="mini" style="background-color:#27ae60;" class="statistics-tag">实时统计</span>
            <span v-if="scope.row.statisticsDimension=='total'" size="mini" style="background-color:#409EFF;" class="statistics-tag">累计统计</span>
            <span v-if="scope.row.statisticsDimension=='trend'" size="mini" style="background-color:#f39c12;" class="statistics-tag">趋势统计</span>
            <span v-if="scope.row.statisticsDimension=='contrast'" size="mini" style="background-color:#e74c3c;" class="statistics-tag">对比统计</span>
            <span v-if="scope.row.statisticsDimension=='merge-trend'" size="mini" style="background-color:#6c5ce7;" class="statistics-tag">合并趋势统计</span>
          </template>
        </el-table-column>
        <el-table-column prop="unitAttribute" label="单位" width="70"></el-table-column>
        <el-table-column prop="statisticsType" label="统计类别" width="100">
          <template slot-scope="scope">
            {{ scope.row.statisticsType=='daily'?'单日'
            :scope.row.statisticsType=='weekly'?'7日'
            :scope.row.statisticsType=='monthly'?'30日'
            :'-' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <div>
              <span @click="dltChart(scope.row)" style="color:red;cursor:pointer;">删除</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <el-drawer
      title=""
      :with-header="false"
      :visible.sync="drawer"
      direction="rtl"
      size="900px">
      <div class="drawer-title">选择图表</div>
      <div class="drawer-content" v-if="drawer">
        <div class="table-tree">
          <div class="tree">
            <div class="tree-title"><i class="el-icon-share"></i> 图表分类</div>
            <el-tree
              v-loading="treeLoading" element-loading-spinner="el-icon-loading"
              :data="configProject"
              node-key="itemId"
              @node-click="treeRowClick"
              :props="defaultProps"
              :expand-on-click-node="false"
              highlight-current
              :current-node-key="chartItemId"
              default-expand-all
              ref="tree">
              <span class="custom-tree-node" slot-scope="{ node }">
                <span style="margin-right: 40px"><i class="el-icon-folder-opened"></i> {{ node.label }}</span>
              </span>
            </el-tree>
          </div>
          <div class="table">
            <el-table :data="tableData" style="width: 100%" height="700" v-loading="chartListLoading" element-loading-spinner="el-icon-loading">
              <el-table-column width="40">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.check" :checked="chartListID.includes(scope.row.graphId)" @change="checked(scope.row)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="graphName" label="图表名称"></el-table-column>
              <el-table-column prop="statisticsDimension" label="图表类型">
                <template slot-scope="scope">
                  <!-- {{ scope.row.statisticsDimension=='realtime'?'实时统计'
                  :scope.row.statisticsDimension=='total'?'累计统计'
                  :scope.row.statisticsDimension=='trend'?'趋势统计'
                  :scope.row.statisticsDimension=='contrast'?'对比统计'
                  :scope.row.statisticsDimension=='merge-trend'?'合并趋势统计'
                  :'-' }} -->
                  <span v-if="scope.row.statisticsDimension=='realtime'" size="mini" style="background-color:#27ae60;" class="statistics-tag">实时统计</span>
                  <span v-if="scope.row.statisticsDimension=='total'" size="mini" style="background-color:#409EFF;" class="statistics-tag">累计统计</span>
                  <span v-if="scope.row.statisticsDimension=='trend'" size="mini" style="background-color:#f39c12;" class="statistics-tag">趋势统计</span>
                  <span v-if="scope.row.statisticsDimension=='contrast'" size="mini" style="background-color:#e74c3c;" class="statistics-tag">对比统计</span>
                  <span v-if="scope.row.statisticsDimension=='merge-trend'" size="mini" style="background-color:#6c5ce7;" class="statistics-tag">合并趋势统计</span>
                </template>
              </el-table-column>
              <el-table-column prop="unitAttribute" label="单位" width="70"></el-table-column>
              <el-table-column prop="statisticsType" label="统计类别" width="100">
                <template slot-scope="scope">
                  {{ scope.row.statisticsType=='daily'?'单日'
                  :scope.row.statisticsType=='weekly'?'7日'
                  :scope.row.statisticsType=='monthly'?'30日'
                  :'-' }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="drawer-bottom">
        <el-button size="small" type="primary" @click="drawer=false">取消</el-button>
        <el-button size="small" type="primary" @click="drawerOK" class="drawer-bottom-ok">确 定</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
// import legendBox1 from '../../chartConfig/chartLegend/legendBox1.vue'
// import legendBox2 from '../../chartConfig/chartLegend/legendBox2.vue'
// import legendBox3 from '../../chartConfig/chartLegend/legendBox3.vue'
// import legendBox4 from '../../chartConfig/chartLegend/legendBox4.vue'
// import legendBox5 from '../../chartConfig/chartLegend/legendBox5.vue'
// import legendBox6 from '../../chartConfig/chartLegend/legendBox6.vue'
// import legendBox7 from '../../chartConfig/chartLegend/legendBox7.vue'
// import legendBox8 from '../../chartConfig/chartLegend/legendBox8.vue'
// import legendBox9 from '../../chartConfig/chartLegend/legendBox9.vue'
// import legendBox10 from '../../chartConfig/chartLegend/legendBox10.vue'
// import legendBox11 from '../../chartConfig/chartLegend/legendBox11.vue'
// import legendBox12 from '../../chartConfig/chartLegend/legendBox12.vue'
// import legendBox13 from '../../chartConfig/chartLegend/legendBox13.vue'
// import legendBox14 from '../../chartConfig/chartLegend/legendBox14.vue'
// import legendBox15 from '../../chartConfig/chartLegend/legendBox15.vue'
import layTime from '@/components/layTime'
export default {
  components: {
    // legendBox1,
    // legendBox2,
    // legendBox3,
    // legendBox4,
    // legendBox5,
    // legendBox6,
    // legendBox7,
    // legendBox8,
    // legendBox9,
    // legendBox10,
    // legendBox11,
    // legendBox12,
    // legendBox13,
    // legendBox14,
    // legendBox15,
    layTime
  },
  props: {
    resource: {
      type: String
    },
    chartLists: {
      type: Array
    }
  },
  data() {
    return {
      treeLoading: false,
      chartListLoading: false,
      form: {},
      rules: {
        name: [{ required: true, message: "必填项", trigger: "blur" }],
      },
      configProject: [],
      tableData: [],
      defaultProps: {
        children: 'childTree',
        label: 'item'
      },
      drawer: false,
      chartItemId: '',
      chartList: [], // 图表列表
      chartListID: [], // 图表graphId列表
      checkedChartList: [],
      currentItemId: null
    };
  },
  methods: {
    // 选择图表
    selectChart() {
      this.loadListData()
      this.chartListID = []
      this.chartList.forEach(item => {
        this.chartListID.push(item.graphId)
      })
      this.checkedChartList = [].concat(this.chartList)
      this.drawer = true
    },
    closeChart() {
      this.chartList = []
      this.$emit('getChartList',this.chartList)
    },
    // 选择项目
    treeRowClick(data) {
      this.chartItemId = data.itemId
      this.currentItemId = data.itemId
      this.loadListData()
    },
    // 勾选图表
    checked(row) {
      if (!this.chartListID.includes(row.graphId)) {
        this.checkedChartList.push(row)
        this.chartListID.push(row.graphId)
      } else {
        this.checkedChartList.forEach((item, index) => {
          if (item.graphId == row.graphId) {
            this.checkedChartList.splice(index, 1)
            this.$delete(this.chartListID, index)
          }
        })
      }
    },
    // 选择图表点击确定
    drawerOK() {
      this.drawer = false
      this.chartList = [].concat(this.checkedChartList)
      this.chartListID = [].concat(this.chartListID)
      this.$emit('getChartList',this.chartList)
    },
    // 删除图表信息
    dltChart(row){
      this.chartList.forEach((item, index) => {
        if (item.graphId == row.graphId) {
          this.chartList.splice(index, 1)
        }
      })
      this.checkedChartList.forEach((v,k) => {
        if (v.graphId == row.graphId) {
          this.checkedChartList.splice(k, 1)
        }
      })
    },
    // 获取图表分类
    async loadConfigProject() {
      this.treeLoading = true
      this.$ajax.post('dataModelItemQuery',{}).then(async res => {
        this.configProject = res.data
        this.chartItemId = res.data[0].itemId
        this.currentItemId = res.data[0].itemId
        this.treeLoading = false
      }).catch(err=>{
        this.treeLoading = false
      })
    },
    // 查询分类下图表列表 
    loadListData() {
      return new Promise((resolve,reject)=>{
        this.chartListLoading = true
        this.$ajax.post('dataModelGraphListQuery',{
          itemId: this.currentItemId
        }).then(res => {
          res.data.forEach(item => {
            let inIs = this.chartListID.find(function(obj) {
              return obj == item.graphId
            })
            if (!inIs) {
              item.check = false
            }else {
              item.check = true
            }
          })

          this.tableData = res.data
          this.chartListLoading = false
          resolve()
        }).catch(err =>{
          this.chartListLoading = false
        })
      })
    },
  },
  watch: {
    chartLists: {
      handler (newVal, oldVal) {
        // 如果列表中值有修改,提交后刷新最新列表
        if(newVal) {
          this.chartList = this.chartLists
        }
      }
    }
  },
  mounted() {
    if(this.resource=='add') {
      this.loadConfigProject()
    }
    if(this.resource=='edit') {
      this.loadConfigProject()
    }
    this.$forceUpdate()
  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading {
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.el-input {
  width: 400px;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 110px)';
  margin-top: 20px;
  .tree {
    width: 220px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.staffBox {
  cursor: pointer;
  width: 390px;
  background: #fff;
  margin-right: 20px;
  height: 40px;
  border-radius: 4px;
  padding: 0 4px;
  border: 1px solid #DCDFE6;
}
/deep/.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #409eff;
  color: white;
}
.statistics-tag {
  padding: 3px 10px;
  border-radius: 4px;
  font-size: 12px;
  display: inline-block;
  box-sizing: border-box;
  white-space: nowrap;
  color: #FFF;
}
</style>